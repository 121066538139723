export const pageAnimation = {

  hidden: {

    opacity: 0

  },

  show: {

    opacity: 1,

    transition: {

      duration: .5

    }

  },
  exit: {

    opacity: 0,

    transition: {

      duration: .2

    }

  }

}